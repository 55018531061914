import { createRouter, createWebHistory } from 'vue-router'
import Customers from "@/views/Customers.vue";
import Authors from "@/views/Authors.vue";
import Courses from "@/views/Courses.vue";
import AddEditCourse from "@/views/AddEditCourse.vue";
import Login from "@/views/Login.vue";
import Admins from "@/views/Admins.vue";
import {store} from "@/store";

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/',
    name: 'Customers',
    component: Customers
  },
  {
    path: '/authors',
    name: 'Authors',
    component: Authors
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/course-category/:id/:action',
    name: 'AddEditCourse',
    component: AddEditCourse
  },
  {
    path: '/admins',
    name: 'Admins',
    component: Admins
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (!store.state.me && to.path !== '/login') {
    next(`/login?redirect=${to.path}`)
  } else next();
});

export default router
