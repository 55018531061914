<template>
  <div
      class="d-flex align-items-center justify-content-center"
      style="height: 100%"
  >
    <div
        class="container py-4 rounded"
        id="register-container"
        style="padding-left: 40px; padding-right: 40px; width: auto"
    >
      <div class="row justify-content-center mb-3">
        <div class="text-center">
          <h2 class="fw-bolder">Anmelden</h2>
        </div>
      </div>
      <form v-on:submit.prevent="submitForm" accept-charset="utf-8">
        <div class="form-group mb-3 has-search">
          <input
              type="email"
              class="form-control"
              id="email"
              placeholder="Deine E-Mail-Adresse"
              required
              v-model="form.email"
          />
        </div>
        <div class="form-group mb-3 has-search">
          <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Dein Passwort"
              required
              v-model="form.password"
          />
        </div>
        <div class="mb-3">
          <button class="btn btn-primary form-control" type="submit" :disabled="loading">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="loading"></span>
            <span>Anmelden</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {login} from "@/account";

export default {
  name: "Login",
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      loading: false
    };
  },
  methods: {
    submitForm() {
      this.loading = true
      this.form.password = unescape(encodeURIComponent(this.form.password));
      login(this.form)
          .catch(() => {
            this.loading = false
          })
          .finally(() => {
            if (!this.$route.query.redirect) {
              this.$router.push("/");
              return;
            }

            this.$router.push(this.$route.query.redirect);
          });
    },
  },
};
</script>

<style scoped>
#register-container {
  background-color: white;
}
</style>
