import axios from "axios";
import {useRoute} from "vue-router";
import {logout} from "@/account";
import router from "@/router";

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
axios.defaults.withCredentials = true

const HTTP = axios.create({})

const responseErrorHandler = async (error: any) => {
    if (error.response.status === 401) {
        await logout()
        await router.push(`/login?redirect=${router.currentRoute.value.path}`)
    }

    return Promise.reject(error);
}

HTTP.interceptors.response.use(
    response => { return response },
    error => responseErrorHandler(error)
)

export default HTTP