<template>
  <div>
    <h2>Dozenten</h2>
    <div class="action-col me-2">
      <a class="btn btn-success" data-bs-toggle="modal" data-bs-target="#addEditModal" @click="clearAddEditData()"><i class="bi bi-plus"></i></a>
    </div>

    <table class="table table-hover" v-if="authors[0]">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Vorname</th>
        <th scope="col">Nachname</th>
        <th scope="col">Onboarding</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="author in authors" :key="author.id">
        <td>{{ author.id }}</td>
        <td>{{ author.firstname }}</td>
        <td>{{ author.lastname }}</td>
        <td><i :class="'bi ' + (author.stripe_payouts_enabled ? 'bi-check-circle text-success' : 'bi-x-circle text-danger')"></i></td>
        <td class="action-col">
          <a :class="'btn' + (author.stripe_account_id ? ' btn-warning' : ' btn-light disabled')" :href="'https://dashboard.stripe.com/connect/accounts/' + author.stripe_account_id + '/activity'" target="_blank"><i class="fab fa-stripe-s"></i></a>
          <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addEditModal" @click="setAddEditData(author)"><i class="bi bi-pencil"></i></a>
          <a class="btn btn-danger"><i class="bi bi-trash"></i></a>
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Add/Edit Modal -->
    <div class="modal fade" id="addEditModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ addEditAuthor?.firstname ? (addEditAuthor.firstname + ' ' + addEditAuthor.lastname) : 'Neuer Dozent' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <span v-if="addEditAuthor.id">ID: {{ addEditAuthor.id }}</span>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="firstname" v-model="addEditAuthor.firstname">
              <label for="firstname">Vorname</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="lastname" v-model="addEditAuthor.lastname">
              <label for="lastname">Nachname</label>
            </div>
            <div class="form-floating mb-3">
              <input type="email" class="form-control" id="email" v-model="addEditAuthor.email">
              <label for="email">E-Mail</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="profile-image" v-model="addEditAuthor.picture_url">
              <label for="profile-image">Profilbild</label>
            </div>
            <div class="form-floating mb-3">
              <input type="text" class="form-control" id="title-image" v-model="addEditAuthor.title_picture_url">
              <label for="title-image">Titelbild</label>
            </div>
            <div class="form-floating mb-3">
              <textarea type="text" class="form-control" id="bio" v-model="addEditAuthor.bio" style="height: 150px"></textarea>
              <label for="bio">Bio</label>
            </div>
            <div class="form-floating mb-3">
              <input type="number" min="2021" max="2099" maxlength="4" class="form-control" id="author-since" v-model="addEditAuthor.author_since">
              <label for="author-since">Dozent seit</label>
            </div>
            <div class="form-floating mb-3">
              <input type="password" class="form-control" id="new-password" v-model="addEditAuthor.password">
              <label for="new-password">Passwort</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="saveAuthor()">Speichern</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/axios";

export default {
  name: "Authors",

  mounted() {
    this.fetchAuthors()
  },

  methods: {
    fetchAuthors: function () {
      HTTP.get('/authors').then((res) => {
        this.authors = res.data
      })
    },

    createAuthor: function (author) {
      HTTP.post('/authors', {
        firstname: author.firstname,
        lastname: author.lastname,
        email: author.email,
        picture_url: author.picture_url,
        title_picture_url: author.title_picture_url,
        bio: author.bio,
        author_since: author.author_since,
        password: author.password
      }).then((res) => {
        console.log(res.data)
        this.fetchAuthors()
      })
    },

    updateAuthor: function (author) {
      let updateObject = {
        firstname: author.firstname,
        lastname: author.lastname,
        email: author.email,
        picture_url: author.picture_url,
        title_picture_url: author.title_picture_url,
        bio: author.bio,
        author_since: author.author_since
      }

      if (author.password) updateObject.password = author.password

      HTTP.patch('/authors/' + author.id, updateObject).then((res) => {
        console.log(res.data)
        this.fetchAuthors()
      })
    },

    saveAuthor: function () {
      if(!this.addEditAuthor.id) {
        this.createAuthor(this.addEditAuthor)
      } else {
        this.updateAuthor(this.addEditAuthor)
      }
    },

    clearAddEditData: function () {
      this.addEditAuthor = this.getEmptyAuthorObject
    },

    setAddEditData: function (author) {
      this.addEditAuthor = {...author}
    },

    getEmptyAuthorObject: function () {
      return {
        id: null,
        firstname: null,
        lastname: null,
        email: null,
        picture_url: null,
        title_picture_url: null,
        bio: null,
        author_since: null,
        password: null
      }
    }
  },

  data() {
    return {
      authors: [],
      addEditAuthor: this.getEmptyAuthorObject()
    }
  }
}
</script>

<style scoped>
.action-col {
  text-align: end;
}

.action-col a {
  margin-right: 5px;
}

.action-col a:last-child {
  margin-right: 0;
}
</style>