<template>
  <div>
    <h2>Admins</h2>

    <table class="table table-hover" v-if="admins[0]">
      <thead>
      <tr>
        <th scope="col">ID</th>
        <th scope="col">Vorname</th>
        <th scope="col">Nachname</th>
        <th scope="col">E-Mail</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="admin in admins" :key="admin.id">
        <td>{{ admin.id }}</td>
        <td>{{ admin.firstname }}</td>
        <td>{{ admin.lastname }}</td>
        <td>{{ admin.email }}</td>
        <td class="action-col">
          <a class="btn btn-primary"><i class="bi bi-pencil"></i></a>
          <a class="btn btn-danger"><i class="bi bi-trash"></i></a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import HTTP from "@/axios";

export default {
  name: "Admins",

  mounted() {
    HTTP.get('/admins').then((res) => {
      this.admins = res.data
    })
  },

  data() {
    return {
      admins: []
    }
  }
}
</script>

<style scoped>
.action-col {
  text-align: end;
}

.action-col a {
  margin-right: 5px;
}

.action-col a:last-child {
  margin-right: 0;
}
</style>