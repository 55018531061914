import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { store } from "@/store";
import {logout} from "@/account";
import mitt from "mitt";

const app = createApp(App)
app.use(store)
app.use(router)

export const emitter = mitt()

app.config.globalProperties.$logout = async () => {
    await logout()
}

store.state.me = JSON.parse(<string>localStorage.getItem('me'))

app.mount("#app")

export default app

