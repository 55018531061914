<template>
<div>
  <h2>Kunden ({{ customers.length }})</h2>

  <table class="table table-hover" v-if="customers[0]">
    <thead>
    <tr>
      <th scope="col">ID</th>
      <th scope="col">Vorname</th>
      <th scope="col">Nachname</th>
      <th scope="col">E-Mail</th>
      <th scope="col">Abo</th>
      <th scope="col"></th>
    </tr>
    </thead>
    <tbody>
        <tr v-for="customer in customers" :key="customer.id">
          <td>{{ customer.id }}</td>
          <td>{{ customer.firstname }}</td>
          <td>{{ customer.lastname }}</td>
          <td>{{ customer.email }}</td>
          <td>{{ customer.subscription?.plan_name }}&nbsp;<i :class="'bi ' + (customer.subscription?.status === 'active' ? 'bi-check-circle text-success' : 'bi-x-circle text-danger')"></i></td>
          <td class="action-col">
            <a :class="'btn' + (customer.stripe_customer_id ? ' btn-warning' : ' btn-light disabled')" target="_blank" :href="'https://dashboard.stripe.com/test/customers/' + customer.stripe_customer_id"><i class="fab fa-stripe-s"></i></a>
            <a class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#editModal" @click="setEditData(customer)"><i class="bi bi-pencil"></i></a>
            <a class="btn btn-danger" data-bs-toggle="modal" data-bs-target="#deletionModal" @click="selectCustomerToDelete(customer)"><i class="bi bi-trash"></i></a>
            </td>
        </tr>
    </tbody>
  </table>

  <!-- Modal -->
  <div class="modal fade" id="deletionModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Kunden {{ customerToDelete.firstname }}&nbsp;{{ customerToDelete.lastname }} entfernen?</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Willst Du wirklich und unwiederbringlich den Kunden <b>{{ customerToDelete.firstname }}&nbsp;{{ customerToDelete.lastname }}</b> entfernen?
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-danger">Löschen</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit Modal -->
  <div class="modal fade" id="editModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel2" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel2">{{ editCustomer?.firstname ? (editCustomer.firstname + ' ' + editCustomer.lastname) : '' }}</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body text-start">
          <span v-if="editCustomer.id">ID: {{ editCustomer.id }}</span>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="firstname" v-model="editCustomer.firstname">
            <label for="firstname">Vorname</label>
          </div>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="lastname" v-model="editCustomer.lastname">
            <label for="lastname">Nachname</label>
          </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="email" v-model="editCustomer.email">
            <label for="email">E-Mail</label>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
          <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="updateCustomer()">Speichern</button>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import HTTP from "@/axios";

export default {
  name: "Customers",

  mounted() {
    this.fetchCustomers()
  },

  methods: {
    fetchCustomers: function () {
      HTTP.get('/customers').then((res) => {
        this.customers = res.data
      })
    },

    selectCustomerToDelete(customer) {
      this.customerToDelete = customer
    },

    setEditData: function (customer) {
      this.editCustomer = {...customer}
    },

    getEmptyCustomerObject: function () {
      return {
        id: null,
        firstname: null,
        lastname: null,
        email: null
      }
    },

    updateCustomer: function () {
      let customer = this.editCustomer
      let updateObject = {
        firstname: customer.firstname,
        lastname: customer.lastname,
        email: customer.email
      }

      HTTP.patch('/customers/' + customer.id, updateObject).then((res) => {
        console.log(res.data)
        this.fetchCustomers()
      })
    },
  },

  data() {
    return {
      customers: [],
      customerToDelete: {},
      editCustomer: this.getEmptyCustomerObject()
    }
  }
}
</script>

<style scoped>
.action-col {
  text-align: end;
}

.action-col a {
  margin-right: 5px;
}

.action-col a:last-child {
  margin-right: 0;
}
</style>