<template>
  <div>
    <h2>Kurse</h2>

    <div v-if="courseCategories[0]">
      <div class="card" v-for="courseCategory in courseCategories" :key="courseCategory.id">
        <div class="card-header">
          <h4>{{ courseCategory.title }}</h4>
        </div>
        <div class="card-body">
          <div class="action-col me-2">
            <router-link class="btn btn-success" :to="`/course-category/${courseCategory.id}/new-course`"><i class="bi bi-plus"></i></router-link>
          </div>
          <table class="table table-hover align-middle">
            <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Name</th>
              <th scope="col"># Videos</th>
              <th scope="col">Dozent-ID</th>
              <th scope="col">Neu?</th>
              <th scope="col">Bald?</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="course in courseCategory.courses" :key="course.id">
              <td>{{ course.id }}</td>
              <td>{{ course.title }}</td>
              <td>{{ course.videos?.length }}</td>
              <td>{{ getAuthorName(course.author_id) }}</td>
              <td><i v-if="course.is_new" class="bi bi-check-circle text-success"></i><i v-if="!course.is_new" class="bi bi-x-circle text-danger"></i></td>
              <td><i v-if="course.coming_soon" class="bi bi-check-circle text-success"></i><i v-if="!course.coming_soon" class="bi bi-x-circle text-danger"></i></td>
              <td class="action-col">
                <router-link class="btn btn-primary" :to="`/course-category/${courseCategory.id}/${course.id}`"><i class="bi bi-pencil"></i></router-link>
                &nbsp;
                <a class="btn btn-danger"><i class="bi bi-trash"></i></a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/axios";

export default {
  name: "Courses",

  mounted() {
    HTTP.get('/authors').then((res) => {
      this.authors = res.data
    })

    HTTP.get('/course_categories').then((res) => {
      this.courseCategories = res.data
    })
  },

  methods: {
    getAuthorName: function (authorId) {
      let author = this.authors.find(a => { return a.id === authorId })
      return `${author?.firstname} ${author?.lastname}`
    }
  },

  data() {
    return {
      courseCategories: [],
      authors: []
    }
  }
}
</script>

<style scoped>
.action-col {
  text-align: end;
}

.action-col a {
  margin-right: 5px;
}

.action-col a:last-child {
  margin-right: 0;
}
</style>