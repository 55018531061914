<template>
  <div>
    <h2>{{ addEditCourse.title || "Neuer Kurs" }}</h2>

    <div class="row mt-5">
      <div class="col"></div>
      <div class="col">
        <div class="form-floating mb-3">
          <input type="text" class="form-control" v-model="addEditCourse.title" placeholder=" ">
          <label>Name</label>
        </div>

        <div class="form-floating mb-3">
          <editor
              :init="{
                placeholder: 'Beschreibung'
              }"
              api-key="45bj8mkwa65m6o5n0rhzlikhzp0919yxbdmtc02eyfdg5xsz"
              v-model="addEditCourse.description"
          />
        </div>

        <div class="form-floating mb-3">
          <select class="form-select" v-model="addEditCourse.author_id">
            <option value=""></option>
            <option v-for="author in authors" :key="author.id" :value="author.id">{{ author.firstname }}&nbsp;{{ author.lastname }}</option>
          </select>
          <label>Dozent</label>
        </div>

        <div class="form-floating mb-3">
          <select class="form-select" v-model="addEditCourse.is_new">
            <option value=""></option>
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
          </select>
          <label>Neu?</label>
        </div>

        <div class="form-floating mb-3">
          <select class="form-select" v-model="addEditCourse.coming_soon">
            <option value=""></option>
            <option :value="true">Ja</option>
            <option :value="false">Nein</option>
          </select>
          <label>Bald verfügbar?</label>
        </div>
      </div>
      <div class="col"></div>
    </div>

    <div class="row">
      <div class="col">
        <h3>Videos</h3>

        <div class="action-col me-2">
          <button class="btn btn-success" data-bs-toggle="modal" data-bs-target="#addEditVideoModal" @click="clearAddEditVideoData()"><i class="bi bi-plus"></i></button>
        </div>
        <table class="table table-hover mw-100" style="table-layout: fixed">
          <thead>
          <tr>
            <th scope="col">Titel</th>
            <th scope="col">Beschreibung</th>
            <th scope="col">Vimeo-ID</th>
            <th scope="col">Sekunden</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="video in addEditCourse.videos" :key="video.id">
            <td>{{ video.title }}</td>
            <td>{{ video.description }}</td>
            <td>{{ video.vimeo_id }}</td>
            <td>{{ video.length }}</td>
            <td class="action-col">
              <div class="row float-end">
                <div class="col-auto">
                  <div class="row">^</div>
                  <div class="row">v</div>
                </div>
                <div class="col-auto">
                  <button class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addEditVideoModal" @click="setAddEditVideoData(video)"><i class="bi bi-pencil"></i></button>
                </div>
                <div class="col-auto">
                  <button class="btn btn-danger" @click="deleteVideo(video)"><i class="bi bi-trash"></i></button>
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row">
      <button class="btn btn-lg btn-success" @click="saveCourse()">Speichern</button>
    </div>

    <!-- Add/Edit Video Modal -->
    <div class="modal fade" id="addEditVideoModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="staticBackdropLabel">{{ addEditVideoData?.title ? addEditVideoData.title : 'Neues Video' }}</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body text-start">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" v-model="addEditVideoData.title">
              <label>Name</label>
            </div>
            <div class="form-floating mb-3">
              <textarea class="form-control" v-model="addEditVideoData.description" />
              <label>Beschreibung</label>
            </div>
            <div class="form-floating mb-3">
              <input type="number" class="form-control" v-model="addEditVideoData.vimeo_id">
              <label>Vimeo-ID</label>
            </div>
            <div class="form-floating mb-3">
              <input type="number" class="form-control" v-model="addEditVideoData.length">
              <label>Video-Länge in Sekunden</label>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Abbrechen</button>
            <button type="button" class="btn btn-success" data-bs-dismiss="modal" @click="saveVideo()">Speichern</button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import HTTP from "@/axios";
import { v4 as uuidv4 } from 'uuid';
import Editor from '@tinymce/tinymce-vue'

export default {
  name: "Courses",

  components: {
    Editor
  },

  mounted() {
    if (this.$route.params.action !== 'new-course') {
      this.loadCourse(this.$route.params.action)
    }

    this.fetchAuthors()
  },

  methods: {
    loadCourse: function (courseId) {
      HTTP.get(`/course_categories/${this.$route.params.id}/${courseId}`).then(res => {
        this.addEditCourse = res.data
      })
    },

    fetchAuthors: function () {
      HTTP.get('/authors').then((res) => {
        this.authors = res.data
      })
    },

    clearAddEditVideoData: function () {
      this.addEditVideoData = {}
    },

    setAddEditVideoData: function (video) {
      this.addEditVideoData = {...video}
    },

    saveVideo: function () {
      if (!this.addEditCourse.videos) this.addEditCourse.videos = []
      if (!this.addEditVideoData.id && !this.addEditVideoData.uuid) this.addEditVideoData.uuid = uuidv4()

      let videoIndex = this.addEditCourse.videos.findIndex(v => {
        if (this.addEditVideoData.id) {
          return v.id === this.addEditVideoData.id
        } else {
          return v.uuid === this.addEditVideoData.uuid
        }
      })

      if (videoIndex === -1) {
        this.addEditCourse.videos.push(this.addEditVideoData)
      } else {
        this.addEditCourse.videos[videoIndex] = this.addEditVideoData
      }

      // this.orderVideos()
    },

    orderVideos: function () {
      this.addEditCourse.videos.sort((video1, video2) => {
        return video1.index - video2.index
      })
    },

    deleteVideo: function (video) {
      this.addEditCourse.videos = this.addEditCourse.videos.filter(v => {
        return v.id !== video.id && v.uuid !== video.uuid
      })
    },

    saveCourse: function () {
      console.log(this.addEditCourse)

      HTTP.post(`/course_categories/${this.$route.params.id}/course/`, this.addEditCourse).then((res) => {
        this.$router.replace('/courses')
      })
    }
  },

  data() {
    return {
      addEditCourse: {},
      addEditVideoData: {},
      authors: []
    }
  }
}
</script>

<style scoped>
.action-col {
  text-align: end;
}

.action-col a {
  margin-right: 5px;
}

.action-col a:last-child {
  margin-right: 0;
}

.truncate {
  max-width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>