<template>
  <!-- Navigation-->
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container px-5">
      <router-link class="navbar-brand" to="/">becademy Admin</router-link>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item"><router-link to="/" class="nav-link">Kunden</router-link></li>
          <li class="nav-item"><router-link to="/authors" class="nav-link">Dozenten</router-link></li>
          <li class="nav-item"><router-link to="/courses" class="nav-link">Kurse</router-link></li>
          <li class="nav-item"><router-link to="/admins" class="nav-link">Admins</router-link></li>
        </ul>
      </div>
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item" v-if="isLoggedIn">
          <a class="btn btn-secondary" @click="$logout(true)">Logout</a>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import {isLoggedIn, logout} from "@/account";
import {emitter} from "@/main";

export default {
  mounted() {
    emitter.on('login', () => {
      this.isLoggedIn = isLoggedIn()
    })
    emitter.on('logout', () => {
      this.isLoggedIn = isLoggedIn()
    })
  },

  data() {
    return {
      isLoggedIn: isLoggedIn()
    }
  }
};
</script>

<style>
nav {
  margin-bottom: 15px;
}
</style>
